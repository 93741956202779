import React, { useState, useEffect } from "react";
import "./form.css";
import topImg from "../Assets/back-to-top.png";

const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Function to handle scroll event
    const handleScroll = () => {
      if (window.pageYOffset > 100) {
        // Adjust the scroll height as needed
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    // Add event listener for scroll
    window.addEventListener("scroll", handleScroll);

    // Cleanup function
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div
      className={`back-to-top-btn ${isVisible ? "show" : ""}`}
      onClick={scrollToTop}
    >
      <img src={topImg} alt="top-btn" />
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="66.984" height="67.065" viewBox="0 0 66.984 67.065">
  <g id="back-to-top" transform="translate(-31.514 -30.971)">
    <path id="Path_397" data-name="Path 397" d="M61.849,31.05q17.56-1.06,29.066,12.1,10.907,14.111,6.127,31.382A33.832,33.832,0,0,1,75,96.579q-17.271,4.78-31.382-6.127Q29.394,77.9,31.886,59q3.375-17.556,19.5-25.181A36.934,36.934,0,0,1,61.849,31.05Zm2.69,23.91a6.8,6.8,0,0,1,4.782,1.868Q76.164,63.6,82.845,70.5q.941,2.9-2.017,3.437a2.475,2.475,0,0,1-1.494-.523l-13.6-13.6a1.508,1.508,0,0,0-1.644.149L50.342,73.715Q47.412,74.79,46.9,71.7a2.073,2.073,0,0,1,.374-1.345L61.251,56.38A7.286,7.286,0,0,1,64.538,54.96Z" fill="#227e1d" fill-rule="evenodd" opacity="0.992"/>
  </g>
</svg> */}
    </div>
  );
};

export default BackToTop;
