import React from "react";
import news from "../Assets/newspaper2.mp4";
import "./form.css";

function Newsroom() {
  return (
    <div className="news-container">
      <video autoPlay loop>
        <source src={news} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {/* <img className="news-gif" src={news} /> */}
    </div>
  );
}

export default Newsroom;
