import logo from "./logo.svg";
import "./App.css";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import Homescreen from "./Homescreen";
import icon from "./Assets/icon.png";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import "bootstrap/dist/css/bootstrap.min.css";
import servesIcon from "./Assets/servesIcon.png";
import drivesIcon from "./Assets/drivesIcon.png";
import homeIcon from "./Assets/myDaddysHomeIcon.png";
import ApplicationForm from "./Components/ApplicationForm";
import Login from "./Components/Login";
import Register from "./Components/Register";
import { useState } from "react";
import LanguageDrawer from "./LanguageDrawer";
import Newsroom from "./Components/Newsroom";
import Careers from "./Components/Careers";
import BackToTop from "./Components/BackToTop";
import GoogleTranslate from "./Components/GoogleTranslate";

function App() {
  const [languageDrawer, setLanguageDrawer] = useState(false);
  const location = useLocation();
  const isLoginActive =
    location.pathname.includes("/login") ||
    location.pathname.includes("/register");

  const handleLanguageDrawer = () => {
    setLanguageDrawer(!languageDrawer);
  };

  return (
    <div className="App">
      {isLoginActive ? (
        <></>
      ) : (
        <div className="nav1-container">
          <div className="navbar1">
            <div className="nav1">
              <img className="icon" src={icon} alt="icon" />
              <div className="nav1-right">
                <div className="newsroom">
                  <ul>
                    <li>
                      <Link to="/newsroom">Newsroom</Link>
                    </li>
                    <span className="hr"></span>
                    <li>
                      <Link to="/careers">Careers</Link>
                    </li>
                    <span className="hr"></span>
                    <li>
                      <GoogleTranslate />
                    </li>
                    {/* <li>
                      <Link onClick={handleLanguageDrawer}>
                        Asia Pacific - English
                      </Link>
                    </li> */}
                    {/*  <span className="hr"></span>
                   <li>
                      <Link to="/login">Login</Link>
                    </li>
                    <span className="hr"></span>
                    <li>
                      <Link to="/register">Register</Link>
                    </li> */}
                  </ul>
                </div>
                <div className="nav1-list">
                  <ul>
                    <li>
                      <a href="/#home">Home</a>
                    </li>
                    <li>
                      <DropdownButton
                        id="dropdown-basic-button"
                        title="About Us"
                        className="my-dropdown"
                      >
                        <Dropdown.Item href="/#aboutUs">
                          About Us Info
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="https://icorp.global/Video/ICorpPaysOverview.mp4"
                          target="_blank"
                        >
                          ICorp Pays Overview
                        </Dropdown.Item>
                      </DropdownButton>
                    </li>
                    {/* <li>
                      <a href="/#aboutUs">About Us</a>
                    </li> */}
                    <li>
                      <DropdownButton
                        id="dropdown-basic-button"
                        title="Services"
                        className="my-dropdown"
                      >
                        <Dropdown.Item href="/#serves">
                          <img className="service-icons" src={servesIcon} />
                        </Dropdown.Item>
                        <Dropdown.Item href="/#drives">
                          <img
                            className="service-icons icon2"
                            src={drivesIcon}
                          />
                        </Dropdown.Item>
                        <Dropdown.Item href="/#myHome">
                          <img
                            className="service-icons myHome-icon"
                            src={homeIcon}
                          />
                        </Dropdown.Item>
                      </DropdownButton>
                    </li>
                    <li>
                      {" "}
                      <DropdownButton
                        id="dropdown-basic-button"
                        title="Partners"
                        className="my-dropdown"
                      >
                        <Dropdown.Item
                          disabled
                          href=""
                          className="partner-wait"
                        >
                          <span> Can't wait to show what we got!</span>
                          <span>Stay tuned!!</span>
                        </Dropdown.Item>
                        {/* <Dropdown.Item href=""></Dropdown.Item>
                         <Dropdown.Item href="#/action-3">
                          Action 3
                        </Dropdown.Item> */}
                      </DropdownButton>
                    </li>
                    <li>
                      <a href="/#contactUs">Contact Us</a>
                    </li>
                    {/* <li>
                      <Link to="/form" className="digital-btn">
                        Digital
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {languageDrawer ? <LanguageDrawer /> : <></>}

      <Routes>
        <Route path="/" element={<Homescreen />} />
        <Route path="/form" element={<ApplicationForm />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/newsroom" element={<Newsroom />} />
        <Route path="/careers" element={<Careers />} />
      </Routes>
      <BackToTop />
    </div>
  );
}

export default App;
