import React, { useState, useRef } from "react";
import { Carousel } from "react-bootstrap";
import carousel3 from "./Assets/carousel3.png";
import carousel2 from "./Assets/carousel2.png";
import carousel1 from "./Assets/carousel1.png";
import "./Style.css";
import aboutUsImg from "./Assets/aboutUsImg.png";
import servesImg from "./Assets/servesIcon.png";
import drivesImg from "./Assets/drivesIcon.png";
import myHomeImg from "./Assets/myDaddysHomeIcon.png";
import contactUsImg from "./Assets/contactUsImg.png";
import servesVideo from "./Assets/servesVideo.mp4";
import drivesVideo from "./Assets/drivesVideo.mp4";
import myHomeVideo from "./Assets/myHomeVideo.mp4";
import serves1 from "./Assets/serves1.png";
import serves2 from "./Assets/serves2.png";
import serves3 from "./Assets/serves3.png";
import drives1 from "./Assets/drives1.png";
import drives2 from "./Assets/drives2.png";
import drives3 from "./Assets/drives3.png";
import myHome1 from "./Assets/myHome1.png";
import myHome2 from "./Assets/myHome2.png";
import myHome3 from "./Assets/myHome3.png";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Popover } from "bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EmailService from "./Services/email.service";
import { Form } from "react-bootstrap";

function Homescreen() {
  const [detail, setDetail] = useState({
    fName: "",
    email: "",
    phone: "",
    message: "",
  });

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    AOS.init();
    const handleScroll = () => {
      AOS.refresh();
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleContactUs = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      const regex = /^[0-9]*$/;
      if (regex.test(value) && value.length <= 10) {
        setDetail({ ...detail, [name]: value });
      }
    } else {
      setDetail({ ...detail, [name]: value });
    }
  };

  const handleSubmitContactUs = (e) => {
    setShowModal(true);
    const formData = {
      userName: detail.fName,
      UserEmail: detail.email,
      Phone: detail.phone,
      Message: detail.message,
    };
    e.preventDefault();
    console.log(formData);

    EmailService.sendEmail(formData)
      .then((response) => {
        // console.log(response.data);
        if (response) {
          setDetail({
            fName: "",
            email: "",
            phone: "",
            message: "",
          });
        } else {
          toast("Failed to send message! Try again", {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          setDetail({
            fName: "",
            email: "",
            phone: "",
            message: "",
          });
          // throw new Error("Failed to send message");
        }
      })
      .catch((e) => {
        // console.log(e);
        setDetail({
          fName: "",
          email: "",
          phone: "",
          message: "",
        });
      });
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();

  useEffect(() => {
    const popover = new Popover(ref1.current, {
      trigger: "hover",
      // title: "Stay tuned!",
      content: "Something's cooking. Stay tuned!",
      placement: "bottom",
    });

    const popover2 = new Popover(ref2.current, {
      trigger: "hover",
      // title: "Stay tuned!",
      content: "Something's cooking. Stay tuned!",
      placement: "bottom",
    });

    const popover3 = new Popover(ref3.current, {
      trigger: "hover",
      // title: "Stay tuned!",
      content: "Something's cooking. Stay tuned!",
      placement: "bottom",
    });
    return () => {
      popover.dispose();
      popover2.dispose();
      popover3.dispose();
    };
  }, []);

  return (
    <div>
      <ToastContainer />
      <Carousel id="home" interval={null}>
        <Carousel.Item>
          <img className="d-block w-100" src={carousel1} alt="First slide" />
          <div className="carousel-overlay"></div>
          <Carousel.Caption className="caption-left" tabIndex="0">
            <h3>
              Sustainable Solutions for Mass-Transit Electric Vehicle Public
              Charging Stations
            </h3>
            <button
              ref={ref1}
              type="button"
              className="btn btn-primary btn-popover"
              data-bs-toggle="popover"
              data-bs-custom-class="custom-popover"
            >
              Explore Products
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 24.354 24.397"
              >
                <g
                  id="arrow-right_1_"
                  data-name="arrow-right (1)"
                  transform="translate(-54.167 -54.043)"
                >
                  <path
                    id="Path_2"
                    data-name="Path 2"
                    d="M65.211,54.07A12.2,12.2,0,0,1,78.142,69.394,12.031,12.031,0,0,1,72.176,77a12.454,12.454,0,0,1-9.448.909,12.206,12.206,0,0,1-5.845-19.351A12.535,12.535,0,0,1,65.211,54.07ZM68,61.944a1.034,1.034,0,0,1,.727.212l3.483,3.483a.971.971,0,0,1,0,1.211q-1.774,1.744-3.483,3.543a.926.926,0,0,1-1.363-.515.9.9,0,0,1,.242-.909L69.269,67.3l-8.055-.061a1.016,1.016,0,0,1-.878-1.3,1.116,1.116,0,0,1,.878-.7l7.995-.061L67.422,63.4A.971.971,0,0,1,68,61.944Z"
                    fill="#142a68"
                    fill-rule="evenodd"
                    opacity="0.991"
                  />
                </g>
              </svg>
            </button>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={carousel3} alt="Second slide" />
          <div className="carousel-overlay"></div>
          <Carousel.Caption className="caption-left">
            <h3>
              {" "}
              Automated Solutions for Revenue Sharing to Channel Partners{" "}
            </h3>
            <button
              ref={ref2}
              type="button"
              className="btn btn-primary btn-popover"
              data-bs-toggle="popover"
              data-bs-custom-class="custom-popover"
            >
              Explore Products
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 24.354 24.397"
              >
                <g
                  id="arrow-right_1_"
                  data-name="arrow-right (1)"
                  transform="translate(-54.167 -54.043)"
                >
                  <path
                    id="Path_2"
                    data-name="Path 2"
                    d="M65.211,54.07A12.2,12.2,0,0,1,78.142,69.394,12.031,12.031,0,0,1,72.176,77a12.454,12.454,0,0,1-9.448.909,12.206,12.206,0,0,1-5.845-19.351A12.535,12.535,0,0,1,65.211,54.07ZM68,61.944a1.034,1.034,0,0,1,.727.212l3.483,3.483a.971.971,0,0,1,0,1.211q-1.774,1.744-3.483,3.543a.926.926,0,0,1-1.363-.515.9.9,0,0,1,.242-.909L69.269,67.3l-8.055-.061a1.016,1.016,0,0,1-.878-1.3,1.116,1.116,0,0,1,.878-.7l7.995-.061L67.422,63.4A.971.971,0,0,1,68,61.944Z"
                    fill="#142a68"
                    fill-rule="evenodd"
                    opacity="0.991"
                  />
                </g>
              </svg>
            </button>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={carousel2} alt="Third slide" />
          <div className="carousel-overlay"></div>
          <Carousel.Caption className="caption-left">
            <h3>Smart Solutions with Benefits & Gains to Customers </h3>
            <button
              ref={ref3}
              type="button"
              className="btn btn-primary btn-popover"
              data-bs-toggle="popover"
              data-bs-custom-class="custom-popover"
            >
              Explore Products
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 24.354 24.397"
              >
                <g
                  id="arrow-right_1_"
                  data-name="arrow-right (1)"
                  transform="translate(-54.167 -54.043)"
                >
                  <path
                    id="Path_2"
                    data-name="Path 2"
                    d="M65.211,54.07A12.2,12.2,0,0,1,78.142,69.394,12.031,12.031,0,0,1,72.176,77a12.454,12.454,0,0,1-9.448.909,12.206,12.206,0,0,1-5.845-19.351A12.535,12.535,0,0,1,65.211,54.07ZM68,61.944a1.034,1.034,0,0,1,.727.212l3.483,3.483a.971.971,0,0,1,0,1.211q-1.774,1.744-3.483,3.543a.926.926,0,0,1-1.363-.515.9.9,0,0,1,.242-.909L69.269,67.3l-8.055-.061a1.016,1.016,0,0,1-.878-1.3,1.116,1.116,0,0,1,.878-.7l7.995-.061L67.422,63.4A.971.971,0,0,1,68,61.944Z"
                    fill="#142a68"
                    fill-rule="evenodd"
                    opacity="0.991"
                  />
                </g>
              </svg>
            </button>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <div className="aboutUs-container" id="aboutUs">
        <svg
          className="about-svg1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 414.35 414.322"
        >
          <g
            id="Group_16"
            data-name="Group 16"
            transform="translate(-5995.863 -1507.947)"
            opacity="0.5"
          >
            <path
              id="Path_100"
              data-name="Path 100"
              d="M518.286,856.444a5.054,5.054,0,1,0,0,10.082c28.1,0,40.651-23.2,53.932-47.775,17.287-31.994,36.86-68.289,94.825-72.913a14.975,14.975,0,0,0,12.636-20.352l-25.81-68.853A180.134,180.134,0,0,0,703.634,622.7a5.109,5.109,0,0,0,1.4-4.382,5.027,5.027,0,0,0-2.688-3.71l-80.791-40.328a3.711,3.711,0,0,0-1.264-.43L519.308,553.634a5.215,5.215,0,0,0-6.076,3.844,5.055,5.055,0,0,0,3.952,5.942l98.239,19.626,25.353,67.617a70.651,70.651,0,0,1-21.508,3.737H518.286a5.055,5.055,0,1,0,0,10.082H619.268a80.354,80.354,0,0,0,25.165-4.355l25.81,68.827a5.081,5.081,0,0,1-4.033,6.748c-63.4,5.082-85.28,45.705-102.864,78.156C550.495,837.677,540.386,856.444,518.286,856.444Zm110.23-267.456,62.724,31.375a169.839,169.839,0,0,1-40.946,26.885Z"
              transform="translate(5483 974.926)"
              fill="#c3e3a8"
            />
            <path
              id="Path_101"
              data-name="Path 101"
              d="M628.932,636.15a85.845,85.845,0,1,0,85.818,85.827A85.845,85.845,0,0,0,628.932,636.15Zm0,161.554a75.736,75.736,0,1,1,75.736-75.736A75.736,75.736,0,0,1,628.932,797.7Z"
              transform="translate(5533.989 1114.429)"
              fill="#c3e3a8"
            />
            <path
              id="Path_102"
              data-name="Path 102"
              d="M616.251,643.66A65.654,65.654,0,1,0,681.878,709.3,65.654,65.654,0,0,0,616.251,643.66Zm0,121.172A55.545,55.545,0,1,1,671.769,709.3,55.545,55.545,0,0,1,616.251,764.832Z"
              transform="translate(5546.67 1127.11)"
              fill="#c3e3a8"
            />
            <path
              id="Path_103"
              data-name="Path 103"
              d="M624.731,682.938l11.077-11.077a5.057,5.057,0,1,0-7.151-7.151L617.58,675.787a25.648,25.648,0,0,0-8.872-3.683V656.456a5.055,5.055,0,1,0-10.082,0V672.1a25.652,25.652,0,0,0-8.872,3.683L578.677,664.71a5.054,5.054,0,0,0-7.125,7.151l11.077,11.077a25.192,25.192,0,0,0-3.71,8.872H563.406a5.054,5.054,0,1,0,0,10.082h15.647a25.189,25.189,0,0,0,3.71,8.872l-11.292,11.077a5.038,5.038,0,1,0,7.125,7.125l11.077-11.077a24.653,24.653,0,0,0,8.872,3.683v15.674a5.054,5.054,0,1,0,10.082,0V721.573a24.652,24.652,0,0,0,8.872-3.683l11.077,11.077a5.054,5.054,0,0,0,7.151-7.125L624.65,710.765a25.646,25.646,0,0,0,3.683-8.872h15.728a5.054,5.054,0,1,0,0-10.082H628.414A25.654,25.654,0,0,0,624.731,682.938Zm-21,29.063a15.163,15.163,0,1,1,15.136-15.163A15.164,15.164,0,0,1,603.734,712Z"
              transform="translate(5559.188 1139.559)"
              fill="#c3e3a8"
            />
            <path
              id="Path_104"
              data-name="Path 104"
              d="M581.174,608.681a5.055,5.055,0,1,0,0-10.082H540.846a5.054,5.054,0,1,0,0,10.082Z"
              transform="translate(5521.093 1050.999)"
              fill="#c3e3a8"
            />
            <path
              id="Path_105"
              data-name="Path 105"
              d="M609.616,616.192a14.463,14.463,0,0,1,7.5,2.124,5.054,5.054,0,1,0,5.162-8.684,24.573,24.573,0,0,0-12.663-3.522,25.245,25.245,0,1,0,0,50.491,24.842,24.842,0,0,0,12.663-3.522,5.054,5.054,0,1,0-5.162-8.684,14.438,14.438,0,0,1-7.5,2.1,15.163,15.163,0,1,1,0-30.3Z"
              transform="translate(5603.743 1063.705)"
              fill="#c3e3a8"
            />
            <path
              id="Path_106"
              data-name="Path 106"
              d="M518.252,899.6H558.58a25.272,25.272,0,0,0,25.245-25.245,95.947,95.947,0,1,1,191.894,0,25.245,25.245,0,1,0,50.477,0V858.706a25.273,25.273,0,0,0,20.191-24.735V793.509A25.272,25.272,0,0,0,826.2,768.774V753.181a50.166,50.166,0,0,0-2.957-15.109h38.312a45.517,45.517,0,0,0,45.436-45.463V616.872h15.136a5.054,5.054,0,0,0,5.054-5.028V571.517a5.055,5.055,0,0,0-5.055-5.055V551.326a5.054,5.054,0,1,0-10.082,0v15.136H891.824V551.326a5.054,5.054,0,1,0-10.082,0v15.136a5.054,5.054,0,0,0-5.054,5.054v40.328a5.055,5.055,0,0,0,5.054,5.028h15.136v75.736a35.381,35.381,0,0,1-35.327,35.354h-42.1c-20.03-46.35-78.344-118.86-92.4-136.013l16.938-16.965a4.974,4.974,0,0,0,1.183-5.377,5.081,5.081,0,0,0-4.3-3.334l-222.154-20.11a5.108,5.108,0,0,0-5.377,4.571,5.054,5.054,0,0,0,4.571,5.377l211.427,19.223-12.582,12.582a5.082,5.082,0,0,0-.3,6.8c22.234,26.7,72.913,91.948,92.163,133.109h-78.29a5.054,5.054,0,0,0,0,10.109h82.431a45.976,45.976,0,0,1,3.388,15.11V768.21H780.815a5.054,5.054,0,0,0-5.054,5.027v34.145a5,5,0,0,0,.86,2.689,108.561,108.561,0,0,1,17.583,44.6,5.028,5.028,0,0,0,4.974,4.275h16.965v15.136a15.163,15.163,0,1,1-30.3,0,106.029,106.029,0,1,0-212.058,0,15.19,15.19,0,0,1-15.15,15.163H518.305a5.054,5.054,0,1,0,0,10.082ZM886.9,606.79v-30.3h30.273v30.3ZM803.56,849.108a118.1,118.1,0,0,0-17.61-43.017V778.426H821.3a15.19,15.19,0,0,1,15.056,15.083v40.328A15.163,15.163,0,0,1,821.2,848.974Z"
              transform="translate(5483.034 962.043)"
              fill="#c3e3a8"
            />
          </g>
        </svg>
        <div
          data-aos="fade-right"
          data-aos-duration="1000"
          className="aboutUs-left"
        >
          <img src={aboutUsImg} alt="car-image" />
        </div>
        <div
          className="aboutUs-right"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <span>ABOUT US</span>
          <div>
            I Corp Pays Pvt Ltd was incorporated in 2014 with the Ministry of
            Company Affairs, GoI;
            <div>
              Incubators: Science & Technology Park, Pune University validated
              the Business Model and recommended as Innovative to the Ministry
              of Commerce & Industry, GoI;
            </div>{" "}
            I Corp was awarded a StartUp - Certificate of Recognition in 2017 by
            Department of Industrial Policy & Promotions, GoI; I Corp initiated
            the workability of prototype on-site & got certified as MSME in
            2021; I Corp leads the proposition from 2024 to locations of
            mass-transit in India after acquisition of the International Patent
            in 2023.
          </div>
          <div>
            I Corp is the premier establishment with a unique model of
            workable-scalable, Digitalized, Renewable Energy Powered Electric
            Vehicle (EV) Public Charging Station (PCS) at any LOA Parking Plot
            on GoI recommended Revenue Sharing Model.
          </div>
          <div>
            I Corp is setting up ‘SERVES’ - ‘Sustainable Energy Recharge
            Vehicles Ezone Stations’ on patented utilities process system of
            financial transaction processing & payment settlement with ‘DriEVs’
            - ‘Digitization for Renewables with Allied Informatics to Electric
            Vehicles for Sustainability’ concept design for network to generate
            revenue making it a sustainable & profitable venture.
          </div>
        </div>
        <svg
          className="about-svg2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 556.111 556.111"
        >
          <g
            id="Group_15"
            data-name="Group 15"
            transform="translate(0)"
            opacity="0.37"
          >
            <path
              id="Path_76"
              data-name="Path 76"
              d="M470.728,444.193a33.908,33.908,0,1,1,67.817,0,33.114,33.114,0,0,1-11.267,25.025,6.789,6.789,0,0,0-.65,9.569,6.861,6.861,0,0,0,9.606.65,46.692,46.692,0,0,0,15.853-35.245,47.486,47.486,0,0,0-94.936,0,46.619,46.619,0,0,0,15.889,35.245,6.789,6.789,0,0,0,8.919-10.219A33.078,33.078,0,0,1,470.728,444.193Z"
              transform="translate(-321.517 -44.081)"
              fill="#118e1d"
              opacity="0.18"
            />
            <path
              id="Path_77"
              data-name="Path 77"
              d="M471.532,361.946H487.71l-22.245-44.417a6.789,6.789,0,1,1,12.133-6.067l27.12,54.167a6.789,6.789,0,0,1-6.067,9.822H482.546l22.208,44.417a6.789,6.789,0,1,1-12.133,6.067L465.5,371.768a6.789,6.789,0,0,1,6.067-9.822Z"
              transform="translate(-301.99 -280.551)"
              fill="#118e1d"
              opacity="0.18"
            />
            <path
              id="Path_78"
              data-name="Path 78"
              d="M672.175,469.849h-291.6V449.519a6.789,6.789,0,0,0-13.578,0v27.12a6.789,6.789,0,0,0,6.789,6.789H672.175a6.789,6.789,0,1,0,0-13.578Z"
              transform="translate(-122.853 72.684)"
              fill="#118e1d"
              opacity="0.18"
            />
            <path
              id="Path_79"
              data-name="Path 79"
              d="M693.757,530.57H571.666V849.323a6.789,6.789,0,0,1-6.753,6.789H456.4a6.789,6.789,0,1,1,0-13.578H557.943V523.889a6.789,6.789,0,0,1,6.789-6.789h20.33V475.645a33.945,33.945,0,0,1-27.119-33.222V333.908A33.945,33.945,0,0,1,591.852,300H673.5a33.945,33.945,0,0,1,33.909,33.908V442.422a33.944,33.944,0,0,1-27.155,33.222v41.383h20.367a6.789,6.789,0,0,1,6.789,6.789V767.964a6.789,6.789,0,0,1-13.578,0ZM666.638,476.4H598.821v40.625h67.817Zm6.861-13.65a20.367,20.367,0,0,0,20.331-20.331V333.908A20.367,20.367,0,0,0,673.5,313.578H592.1a20.367,20.367,0,0,0-20.367,20.331V442.422A20.367,20.367,0,0,0,592.1,462.753Z"
              transform="translate(-449.61 -300)"
              fill="#118e1d"
              opacity="0.18"
            />
            <path
              id="Path_80"
              data-name="Path 80"
              d="M545.313,367.61a6.789,6.789,0,0,1,6.753,6.789v54.167a6.789,6.789,0,0,1-6.753,6.789H463.919a6.789,6.789,0,0,1-6.789-6.789V374.4a6.789,6.789,0,0,1,6.789-6.789Zm-6.789,13.578H470.708v40.661h67.745Z"
              transform="translate(-321.424 -123.463)"
              fill="#118e1d"
              opacity="0.18"
            />
            <path
              id="Path_81"
              data-name="Path 81"
              d="M692.614,395.732v40.661h47.378a6.789,6.789,0,0,1,0,13.578H692.614v13.578a6.789,6.789,0,0,1-6.861,6.68H604.358a6.9,6.9,0,0,1-4.082-1.336l-27.119-20.33a6.824,6.824,0,0,1-2.708-5.453V422.779H522.892a20.366,20.366,0,0,0-20.33,20.33v54.167a20.367,20.367,0,0,0,20.331,20.331H685.753a33.944,33.944,0,0,1,33.908,33.908v54.167a33.944,33.944,0,0,1-33.908,34.269H373.789A6.789,6.789,0,0,1,367,633.162V538.118a6.789,6.789,0,1,1,13.578,0V626.3H685.753a20.367,20.367,0,0,0,20.331-20.331V551.8a20.367,20.367,0,0,0-20.331-20.331H522.892a33.944,33.944,0,0,1-33.908-33.908V443.4a33.944,33.944,0,0,1,33.908-33.908H570.45V389.159a6.825,6.825,0,0,1,2.708-5.453l27.12-20.331a6.9,6.9,0,0,1,4.081-1.336h81.394a6.789,6.789,0,0,1,6.789,6.753v13.578h47.45a6.789,6.789,0,1,1,0,13.578Zm-13.578-20.367H606.814l-22.606,16.972v47.45l22.606,16.972h72.222Z"
              transform="translate(-190.669 -138.007)"
              fill="#118e1d"
              opacity="0.18"
            />
          </g>
        </svg>
      </div>
      <div className="mission-container" id="mission">
        {/* <img className="missionImg" src={missionImg} alt="mission" /> */}
        <div className="missionImg">
          <div className="mission-left">
            <div
              className="missionClass"
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <span>MISSION</span>
              <div>
                Sustainable Model of Power Consumption from Renewable Resources
                for go-green journey.
                <div>
                  Reduction of Fossil Usage by e-Mobility adaptation to reduce
                  Carbon Footprints.
                </div>
                <div>
                  Save Water for Non-Drinkable Purposes & Reuse-Reduce-Recycle
                  waste generated for co-existence of Nature.
                </div>
                <div>
                  Guide the Gen-X to conscientiously share Nature’s prosperities
                  in their daily needs.
                </div>
              </div>
            </div>
            <div
              className="missionClass vissionClass"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <span>VISION</span>
              <div>
                Promotion of Go-Green initiatives by using Natural resources and
                conserve our Nature for future generations.
                <div>
                  Consumers share ‘Benefits to Gains’ and suffice their Needs &
                  Deeds for daily existence.
                </div>
                <div>
                  Pass-over the ‘Ray of Hope’ of a Healthy-Wealthy Bharat to
                  Gen-X of the World.
                </div>
              </div>
            </div>
          </div>
          <div className="mission-right"></div>
        </div>
      </div>
      <div className="serves-container" id="serves">
        <div className="serves">
          <img
            src={servesImg}
            alt="servesImg"
            data-aos="zoom-in"
            data-aos-duration="1700"
          />
          <video
            autoPlay
            loop
            controls
            // data-aos="zoom-out-up"
            // data-aos-duration="400"
          >
            <source src={servesVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="serves-orange">
            <svg
              className="orange-left-svg"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 426.719 426.691"
            >
              <g
                id="_1"
                data-name="1"
                transform="translate(0 -0.04)"
                opacity="0.13"
              >
                <path
                  id="Path_181"
                  data-name="Path 181"
                  d="M50.463,56.725H38.074L55.119,22.609a5.183,5.183,0,0,0-2.328-6.984,5.238,5.238,0,0,0-6.984,2.328L24.993,59.525a5.183,5.183,0,0,0,4.656,7.511H42.065l-17.072,34.2a5.21,5.21,0,0,0,9.312,4.656L55.119,64.319a5.21,5.21,0,0,0-4.656-7.538Z"
                  transform="translate(17.188 5.672)"
                  fill="#0773d9"
                />
                <path
                  id="Path_182"
                  data-name="Path 182"
                  d="M9.42,33.634v83.143a26.079,26.079,0,0,0,20.814,25.5v31.788H14.63a5.21,5.21,0,0,0-5.21,5.183V429.09a5.21,5.21,0,0,0,5.21,5.21h104.1a5.238,5.238,0,0,0,5.21-5.21V357.033a43.955,43.955,0,0,0,26.024,9.506,43.762,43.762,0,0,0,26.024-9.506V376.96a15.6,15.6,0,0,0,15.6,15.6h5.127v26.024a15.631,15.631,0,0,0,15.6,15.631h20.841a15.631,15.631,0,0,0,15.6-15.631V392.563H363.277v26.024a15.631,15.631,0,0,0,15.6,15.631h20.813a15.659,15.659,0,0,0,15.631-15.631V392.563h5.21a15.631,15.631,0,0,0,15.6-15.6V325a26.024,26.024,0,0,0-21.867-25.664l-15.908-63.937a25.968,25.968,0,0,0-25.248-19.7H239.089a26,26,0,0,0-25.276,19.7L197.85,299.332A26.024,26.024,0,0,0,175.984,325v18.569c-17.71,16.795-34.366,16.795-52.048,0V179.245a5.21,5.21,0,0,0-5.21-5.183H103.095V142.3a26.052,26.052,0,0,0,20.841-25.525V33.634A26.052,26.052,0,0,0,97.912,7.61H35.444A26.052,26.052,0,0,0,9.42,33.634Zm394.1,265.448H208.659l15.271-60.972a15.6,15.6,0,0,1,15.16-11.806H373.116a15.6,15.6,0,0,1,15.16,11.806ZM425.69,356.34H373.753V335.527H425.8Zm-52.048,36.417h31.234v26.024a5.21,5.21,0,0,1-5.21,5.21H378.853a5.21,5.21,0,0,1-5.21-5.21Zm-166.536,0h31.317v26.024a5.21,5.21,0,0,1-5.183,5.21H212.4a5.21,5.21,0,0,1-5.183-5.21Zm-20.841-57.23h52.158V356.34H186.376Zm0-10.421A15.659,15.659,0,0,1,201.9,309.475H410.115a15.631,15.631,0,0,1,15.6,15.631H373.753a10.393,10.393,0,0,0-10.393,10.421V356.34a10.393,10.393,0,0,0,10.393,10.393H425.8v10.421a5.21,5.21,0,0,1-5.183,5.21H191.587a5.238,5.238,0,0,1-5.21-5.21V366.733h52.048a10.393,10.393,0,0,0,10.421-10.393V335.527a10.421,10.421,0,0,0-10.421-10.421ZM113.571,184.456V423.88H19.841V184.456ZM40.654,174.063V142.829H92.7v31.234ZM19.841,33.634A15.659,15.659,0,0,1,35.444,18H97.912a15.659,15.659,0,0,1,15.659,15.631v83.143a15.631,15.631,0,0,1-15.6,15.6H35.444a15.631,15.631,0,0,1-15.6-15.6Z"
                  transform="translate(-9.42 -7.57)"
                  fill="#414141"
                />
                <path
                  id="Path_183"
                  data-name="Path 183"
                  d="M104.528,135.289h83.282a5.21,5.21,0,1,1,0,10.393H104.528a5.21,5.21,0,0,1,0-10.393Z"
                  transform="translate(150.5 218.581)"
                  fill="#414141"
                />
                <path
                  id="Path_184"
                  data-name="Path 184"
                  d="M104.77,127.76h83.282a5.21,5.21,0,1,1,0,10.421H104.77a5.21,5.21,0,1,1,0-10.421Z"
                  transform="translate(150.258 205.268)"
                  fill="#414141"
                />
                <path
                  id="Path_185"
                  data-name="Path 185"
                  d="M104.77,120.25h83.282a5.21,5.21,0,1,1,0,10.421H104.77a5.21,5.21,0,1,1,0-10.421Z"
                  transform="translate(150.258 191.965)"
                  fill="#414141"
                />
                <path
                  id="Path_186"
                  data-name="Path 186"
                  d="M89.792,121.982V80.41a5.21,5.21,0,0,0-5.183-5.21H22.141a5.21,5.21,0,0,0-5.21,5.21v41.572a5.238,5.238,0,0,0,5.21,5.321H84.609a5.21,5.21,0,0,0,5.183-5.321ZM79.4,116.772H27.351V85.537H79.4Z"
                  transform="translate(3.883 112.162)"
                  fill="#0773d9"
                />
                <path
                  id="Path_187"
                  data-name="Path 187"
                  d="M61.957,102.294a5.266,5.266,0,0,1,7.15,1.829,25.691,25.691,0,0,1,3.631,13.053,26.024,26.024,0,0,1-52.048,0,25.414,25.414,0,0,1,3.631-13.053,5.21,5.21,0,1,1,8.952,5.321,14.91,14.91,0,0,0-2.189,7.732,15.631,15.631,0,1,0,31.234,0,14.883,14.883,0,0,0-2.162-7.732,5.238,5.238,0,0,1,1.8-7.15Z"
                  transform="translate(10.544 158.594)"
                  fill="#414141"
                />
                <path
                  id="Path_188"
                  data-name="Path 188"
                  d="M28.2,123.734V102.92a5.21,5.21,0,0,1,10.421,0v20.814a5.21,5.21,0,1,1-10.421,0Z"
                  transform="translate(23.848 152.037)"
                  fill="#414141"
                />
                <path
                  id="Path_189"
                  data-name="Path 189"
                  d="M84.851,153.192H22.383a5.21,5.21,0,1,1,0-10.393H84.851a5.21,5.21,0,0,1,0,10.393Z"
                  transform="translate(3.641 231.884)"
                  fill="#414141"
                />
                <path
                  id="Path_190"
                  data-name="Path 190"
                  d="M84.851,145.652H22.383a5.21,5.21,0,1,1,0-10.393H84.851a5.21,5.21,0,0,1,0,10.393Z"
                  transform="translate(3.641 218.528)"
                  fill="#414141"
                />
              </g>
            </svg>
            <svg
              className="orange-right-svg"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 154 154"
            >
              <g
                id="Group_97"
                data-name="Group 97"
                transform="translate(-7288 -3724)"
                opacity="0.13"
              >
                <path
                  id="Path_283"
                  data-name="Path 283"
                  d="M633,240.39h27.4L646.19,283a1.88,1.88,0,0,0,3.29,1.72l45.07-60.1a1.88,1.88,0,0,0-1.5-3H665.6L679.81,179a1.89,1.89,0,0,0-3.29-1.73l-45.07,60.11a1.88,1.88,0,0,0,1.55,3.01Zm39.63-51.59-11.36,34.09a1.88,1.88,0,0,0,1.78,2.48h26.29L653.42,273.2l11.36-34.09a1.87,1.87,0,0,0-1.78-2.47H636.71Z"
                  transform="translate(6702 3570)"
                  fill="#0773d9"
                />
                <path
                  id="Path_284"
                  data-name="Path 284"
                  d="M588.47,307.9l34.21-11.36A75.94,75.94,0,0,0,663,308a77,77,0,1,0-77-77,75.94,75.94,0,0,0,11.46,40.32L586.1,305.53a1.87,1.87,0,0,0,2.37,2.37Zm12.83-36.24a1.87,1.87,0,0,0-.2-1.61A72.19,72.19,0,0,1,589.76,231,73.24,73.24,0,1,1,663,304.24a72.17,72.17,0,0,1-39-11.34,1.85,1.85,0,0,0-1.61-.2l-31.5,10.46Z"
                  transform="translate(6702 3570)"
                  fill="#414141"
                />
              </g>
            </svg>
          </div>
        </div>
        <div className="serves2">
          <div
            className="serves-detail"
            data-aos="fade-in"
            data-aos-duration="1000"
          >
            The global shift towards renewable energy with transition to
            electric mobility is a crucial step towards reducing carbon
            emissions & achieving energy independence revolutionizing India’s
            transportation sector. Our proposed
            <span className="serves-highlight"> 'SERVES'</span> - '
            <span className="serves-bold">
              Sustainable Energy Recharge Vehicles Ezone Stations
            </span>
            ' fills the vacuum of the significant challenge for the lack of an
            efficient public charging infrastructure in India.
          </div>
          <div className="what-we-do-container">
            <span data-aos="flip-up" data-aos-duration="1000">
              WHAT WE DO
            </span>
            <div className="serves-block">
              <div
                className="serveBox"
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <img src={serves1} alt="car-image" />

                <div>
                  Provides efficient public charging infrastructure for Electric
                  Vehicle taxis to fleets.
                </div>
              </div>
              <div
                className="serveBox"
                data-aos="fade-in"
                data-aos-duration="1000"
              >
                <img src={serves2} alt="car-image" />
                <div>
                  Promotes clean energy usage through natural resources &
                  renewable integration.
                </div>
              </div>

              <div
                className="serveBox"
                data-aos="fade-left"
                data-aos-duration="1000"
              >
                <img src={serves3} alt="car-image" />
                <div>
                  Benefits the Taxi Aggregators & Fleet Operators, Drivers,
                  Passengers and General Public too.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="serves-container drives-container" id="drives">
        <div className="serves">
          <img
            src={drivesImg}
            alt="drivesImg"
            data-aos="zoom-in"
            data-aos-duration="1700"
          />
          <video
            autoPlay
            loop
            controls
            // data-aos="zoom-out-up"
            // data-aos-duration="400"
          >
            <source src={drivesVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="drives-green">
            <svg
              className="green-left-svg"
              xmlns="http://www.w3.org/2000/svg"
              width="362.459"
              height="362.369"
              viewBox="0 0 362.459 362.369"
            >
              <g
                id="Group_116"
                data-name="Group 116"
                transform="translate(-6822 -4116)"
                opacity="0.13"
              >
                <path
                  id="Path_270"
                  data-name="Path 270"
                  d="M195.192,788.518a4.283,4.283,0,0,0-4.988,3.765,4.424,4.424,0,0,0,3.788,4.988A92.662,92.662,0,0,0,257.524,628.16a4.425,4.425,0,0,0-4.871,7.389A83.815,83.815,0,0,1,195.216,788.5Z"
                  transform="translate(6796.931 3680.176)"
                  fill="#d9d9d9"
                />
                <path
                  id="Path_271"
                  data-name="Path 271"
                  d="M262.633,621.993A92.662,92.662,0,0,0,199.1,791.1a4.447,4.447,0,0,0,6.141-1.27,4.4,4.4,0,0,0-1.271-6.118A83.814,83.814,0,0,1,261.41,630.77a4.283,4.283,0,0,0,4.988-3.788,4.4,4.4,0,0,0-3.765-4.989Z"
                  transform="translate(6752.808 3671.683)"
                  fill="#d9d9d9"
                />
                <path
                  id="Path_272"
                  data-name="Path 272"
                  d="M262.036,622.7l-88.45,106.051A4.424,4.424,0,0,0,176.974,736h37.813L190.5,800.728a4.424,4.424,0,0,0,7.53,4.4l88.5-106.074a4.424,4.424,0,0,0-3.412-7.247H245.282l24.283-64.732a4.424,4.424,0,0,0-7.53-4.377Zm-27.271,71.979a4.424,4.424,0,0,0,4.141,5.976H273.66l-65.673,78.779,17.365-46.307a4.424,4.424,0,0,0-4.141-5.976H186.457l65.673-78.779Z"
                  transform="translate(6773.124 3671.66)"
                  fill="#d9d9d9"
                />
                <path
                  id="Path_274"
                  data-name="Path 274"
                  d="M135,609.415a22.095,22.095,0,1,0,22.095-22.095A22.095,22.095,0,0,0,135,609.415Zm8.847,0a13.224,13.224,0,1,0,.047,0Z"
                  transform="translate(6722.295 3625.906)"
                  fill="#d9d9d9"
                />
                <path
                  id="Path_275"
                  data-name="Path 275"
                  d="M121.577,591.2A4.518,4.518,0,0,0,120,594.614v70.708a22.118,22.118,0,0,0,22.095,22.095h2.353a39.742,39.742,0,0,0,66.1,0H391.915a39.742,39.742,0,0,0,66.1,0h2.353a22.119,22.119,0,0,0,22.095-22.1v-35.3a4.376,4.376,0,0,0-1.293-3.105l-17.672-17.7a4.566,4.566,0,0,0-1.741-1.059L408.739,590.5l-69.908-43.813a4.351,4.351,0,0,0-2.353-.682H177.437a4.423,4.423,0,0,0-2.824,1.012Zm180.288-36.354,70.591,44.19h-195.3l20.612-44.19Zm92.121,110.592a30.962,30.962,0,1,0,0-.118Zm-247.467,0a30.97,30.97,0,1,0,0-.118ZM128.847,596.8l50.213-41.837h9.177L166.4,601.7a4.4,4.4,0,0,0,4.024,6.282H388.032a4.424,4.424,0,0,0,2.353-8.165l-71.767-44.849h16.73l69.626,43.507,52.966,17.789,15.648,15.648v33.531a13.177,13.177,0,0,1-11.036,12.941,39.343,39.343,0,0,0,2.212-12.941,39.79,39.79,0,0,0-79.555,0,39.2,39.2,0,0,0,2.353,13.248H214.9a39.2,39.2,0,0,0,2.353-13.247,39.79,39.79,0,0,0-79.556,0,39.343,39.343,0,0,0,2.212,12.941,13.177,13.177,0,0,1-11.036-12.942Z"
                  transform="translate(6702 3570)"
                  fill="#d9d9d9"
                />
                <path
                  id="Path_276"
                  data-name="Path 276"
                  d="M199.544,576.05h17.671a4.424,4.424,0,1,1,0,8.847H199.544a4.424,4.424,0,1,1,0-8.847Z"
                  transform="translate(6803.639 3610.658)"
                  fill="#d9d9d9"
                />
                <path
                  id="Path_277"
                  data-name="Path 277"
                  d="M169.544,576.05h17.671a4.424,4.424,0,1,1,0,8.847H169.544a4.424,4.424,0,1,1,0-8.847Z"
                  transform="translate(6763.048 3610.658)"
                  fill="#d9d9d9"
                />
                <path
                  id="Path_279"
                  data-name="Path 279"
                  d="M146.94,723.249a4.424,4.424,0,0,1-4.4-4.424V621.6a4.424,4.424,0,0,1,8.824,0v92.8h13.271a4.424,4.424,0,0,1,0,8.847Z"
                  transform="translate(6732.497 3666.737)"
                  fill="#d9d9d9"
                />
              </g>
            </svg>
            <svg
              className="green-left-2"
              xmlns="http://www.w3.org/2000/svg"
              width="205.44"
              height="393.973"
              viewBox="0 0 205.44 393.973"
            >
              <g
                id="Group_204"
                data-name="Group 204"
                transform="translate(11863.356 -5005.753)"
                opacity="0.06"
              >
                <path
                  id="Path_417"
                  data-name="Path 417"
                  d="M152.2,465.771H123.16L133.37,194.15h8.6Z"
                  transform="translate(-11898.288 4933.954)"
                  fill="#007a88"
                />
                <path
                  id="Path_418"
                  data-name="Path 418"
                  d="M145.975,206.419,132.26,138.933a1.094,1.094,0,0,0-2.177.218V250.176a1.11,1.11,0,0,0,2.177.327l13.715-43.409a1.241,1.241,0,0,0,0-.675Z"
                  transform="translate(-11890.146 4867.805)"
                  fill="#007a88"
                />
                <path
                  id="Path_419"
                  data-name="Path 419"
                  d="M134.592,203.028,82.976,248.744a1.11,1.11,0,0,0,1.284,1.785l96.07-55.469a1.088,1.088,0,0,0-.784-2.025l-44.454,9.818a1.11,1.11,0,0,0-.5.174Z"
                  transform="translate(-11945.988 4932.587)"
                  fill="#007a88"
                />
                <path
                  id="Path_420"
                  data-name="Path 420"
                  d="M163.382,229.544l65.309,21.9a1.11,1.11,0,0,0,.893-2l-96.048-55.469a1.11,1.11,0,0,0-1.371,1.7L162.9,229.261A1.089,1.089,0,0,0,163.382,229.544Z"
                  transform="translate(-11888.004 4933.588)"
                  fill="#007a88"
                />
                <path
                  id="Path_421"
                  data-name="Path 421"
                  d="M151.344,199.882a13.78,13.78,0,1,1-4.036-9.766A13.8,13.8,0,0,1,151.344,199.882Z"
                  transform="translate(-11897.605 4924.456)"
                  fill="#6acff6"
                />
                <path
                  id="Path_422"
                  data-name="Path 422"
                  d="M145.072,197.681a9.731,9.731,0,1,1-9.731-9.731A9.731,9.731,0,0,1,145.072,197.681Z"
                  transform="translate(-11895.404 4926.657)"
                  fill="#b1eceb"
                />
                <circle
                  id="Ellipse_14"
                  data-name="Ellipse 14"
                  cx="4.855"
                  cy="4.855"
                  r="4.855"
                  transform="translate(-11767.966 5116.436)"
                  fill="#fff"
                />
              </g>
            </svg>
            <svg
              className="green-right-svg"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 538.02 1031.765"
            >
              <g
                id="Group_205"
                data-name="Group 205"
                transform="translate(11863.355 -5005.752)"
                opacity="0.06"
              >
                <path
                  id="Path_417"
                  data-name="Path 417"
                  d="M199.214,905.492H123.16L149.9,194.15h22.52Z"
                  transform="translate(-11755.457 5132.025)"
                  fill="#007a88"
                />
                <path
                  id="Path_418"
                  data-name="Path 418"
                  d="M171.711,317.266,135.793,140.527a2.865,2.865,0,0,0-5.7.57V431.86a2.908,2.908,0,0,0,5.7.855l35.918-113.682a3.25,3.25,0,0,0,0-1.767Z"
                  transform="translate(-11722.938 4867.805)"
                  fill="#007a88"
                />
                <path
                  id="Path_419"
                  data-name="Path 419"
                  d="M218.708,219.281,83.532,339.006a2.908,2.908,0,0,0,3.364,4.675l251.6-145.267a2.851,2.851,0,0,0-2.052-5.3L220.019,218.825a2.906,2.906,0,0,0-1.311.456Z"
                  transform="translate(-11945.987 5126.564)"
                  fill="#007a88"
                />
                <path
                  id="Path_420"
                  data-name="Path 420"
                  d="M214.35,287.346,385.386,344.7a2.908,2.908,0,0,0,2.337-5.245L136.186,194.188a2.908,2.908,0,0,0-3.592,4.447l80.5,87.97A2.852,2.852,0,0,0,214.35,287.346Z"
                  transform="translate(-11714.379 5130.563)"
                  fill="#007a88"
                />
                <path
                  id="Path_421"
                  data-name="Path 421"
                  d="M196.032,222.226a36.089,36.089,0,1,1-10.57-25.576A36.146,36.146,0,0,1,196.032,222.226Z"
                  transform="translate(-11752.73 5094.087)"
                  fill="#6acff6"
                />
                <path
                  id="Path_422"
                  data-name="Path 422"
                  d="M176.579,213.435a25.485,25.485,0,1,1-25.485-25.485A25.484,25.484,0,0,1,176.579,213.435Z"
                  transform="translate(-11743.939 5102.878)"
                  fill="#b1eceb"
                />
                <circle
                  id="Ellipse_14"
                  data-name="Ellipse 14"
                  cx="12.714"
                  cy="12.714"
                  r="12.714"
                  transform="translate(-11613.54 5295.617)"
                  fill="#fff"
                />
              </g>
            </svg>
          </div>
        </div>
        <div className="serves2">
          <div
            className="serves-detail"
            data-aos="fade-in"
            data-aos-duration="1000"
          >
            <span className="drives-highlight">‘DriEVs’</span> - ‘
            <span className="serves-bold">
              Digitization for Renewables with Allied Informatics to Electric
              Vehicles for Sustainability
            </span>
            ’ is the utility pathway concept design accessed for the patented
            payment process system for financial transaction processing &
            settlement addressing the sustainability issue by integrating
            front-line technologies for revenue generation models creating a
            sustainable & profitable venture.
          </div>
          <div className="what-we-do-container drives2">
            <span data-aos="flip-up" data-aos-duration="1000">
              WHAT WE DO
            </span>
            <div className="serves-block drives-block">
              <div
                className="serveBox block1"
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <img src={drives1} alt="car-image" />
                <div>
                  Systemizes the patented payment solutions model of utilities
                  for seamless transactions.
                </div>
              </div>
              <div
                className="serveBox block1"
                data-aos="fade-in"
                data-aos-duration="1000"
              >
                <img src={drives2} alt="car-image" />
                <div>
                  Creates a multi-revenue stream model for long-term
                  sustainability of the program.
                </div>
              </div>
              <div
                className="serveBox block1"
                data-aos="fade-left"
                data-aos-duration="1000"
              >
                <img src={drives3} alt="car-image" />
                <div>
                  Common platform sharing benefits & gains amongst the
                  stakeholders of this holistic approach
                </div>
              </div>
            </div>
            <svg
              className="drives-svg"
              xmlns="http://www.w3.org/2000/svg"
              width="617.786"
              height="801.979"
              viewBox="0 0 617.786 801.979"
            >
              <g
                id="Group_206"
                data-name="Group 206"
                transform="translate(11757.635 -4911.283)"
                opacity="0.06"
              >
                <path
                  id="Path_414"
                  data-name="Path 414"
                  d="M575.329,766.09H714.017L623.071,260.164,693.7,172.59H446.317l70.629,87.574L426,766.09H564.688l5.34,4.015Zm-5.34-516.808h0L460.411,177.85H679.566L570.149,249.443Zm48.746,46.136-48.746,39.43L521.2,295.418l48.786-39.47Zm-48.786,46.1h0l66.935,49.87-67.015,49.95-67.015-49.95,66.975-49.87Zm0,106.245h0l88.939,66.252-88.939,66.252L481.01,514.01Zm93.918,68.983,27.746,154.187L574.285,583.515Zm-89.581-72.275,67.537-50.312L662.742,510.6Zm0-106.405,50.272-40.635,16.061,90.184Zm-8.432,0-66.493,49.549,16.061-90.184Zm0,106.405L477.516,510.4l20.92-116.443Zm0,139.009L448.325,670.928l27.746-154.187Zm4.337,3.212,118.732,88.336L573.843,760.79h-7.308L451.3,675.184Zm12.287,174.224L693.218,678.4l14.817,82.514Zm41.357-468.3-49.267-39.751,101.386-66.574L617.53,258.557ZM464.025,186.282,565.612,252.7l-49.228,39.792,5.862-32.6v-1.164ZM432.143,760.79,447,678.276,557.7,760.79Z"
                  transform="translate(-11853.866 4938.218)"
                  fill="#34bac1"
                />
                <path
                  id="Path_520"
                  data-name="Path 520"
                  d="M673.42,325.2c-154.187,0-243.367-198.918-244.25-200.765l3.654-1.646c.924,2.088,93.235,207.872,251.317,198.275l.241,4.015Q678.721,325.2,673.42,325.2Z"
                  transform="translate(-11840.635 4788.493)"
                  fill="#34bac1"
                />
                <path
                  id="Path_521"
                  data-name="Path 521"
                  d="M684.367,325.195C500.948,319.573,353.186,128.646,351.7,126.839l3.212-2.449C356.358,126.317,503,315.558,684.488,321.139Z"
                  transform="translate(-12074.229 4793.317)"
                  fill="#34bac1"
                />
                <path
                  id="Path_413"
                  data-name="Path 413"
                  d="M539.9,914.6H724.883L603.541,239.745,697.74,122.9H367.8L462,239.745,340.66,914.314H525.645l7.107,5.34ZM532.752,225.25l-.2.2L386.595,129.967H678.948L532.992,225.451Zm65.128,61.514L532.752,339.4l-65.088-52.641,65.088-52.64Zm-65.128,61.394h0l89.3,66.533-89.3,66.654-89.38-66.614,89.3-66.574Zm0,141.7h0l118.612,88.336L532.711,666.53,414.1,578.194l118.612-88.336Zm125.317,92.03L695.05,787.511l-156.6-116.443ZM538.534,485.521l90.1-67.136,27.906,155.071Zm0-141.94,67.015-54.206,21.642,120.459Zm-11.283,0-88.858,66.212,21.6-120.459Zm0,141.941-118.01,87.935,27.906-155.071Zm0,185.386-156.6,116.443,36.981-205.623Zm5.742,4.336L691.356,793.213l-153.5,114.356h-9.918l-153.5-114.356Zm16.422,232.325,147.442-109.7,19.8,110.019Zm55.13-624.619-65.77-52.962,135.516-88.577-78.1,96.608ZM391.735,141.29l135.516,88.577-65.69,53.082,7.509-43.2.281-1.566Zm-42.843,766.4,19.8-110.019L516.369,907.689Z"
                  transform="translate(-12098.295 4793.608)"
                  fill="#34bac1"
                />
              </g>
            </svg>
          </div>
        </div>
      </div>
      <div className="serves-container myHome-container" id="myHome">
        <div className="serves myHome">
          <img
            src={myHomeImg}
            alt="drivesImg"
            data-aos="zoom-in"
            data-aos-duration="1700"
          />
          <video
            autoPlay
            loop
            controls
            // data-aos="zoom-out-up"
            // data-aos-duration="400"
          >
            <source src={myHomeVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="drives-green myHome-blue">
            <svg
              className="green-left-svg"
              xmlns="http://www.w3.org/2000/svg"
              width="362.459"
              height="362.369"
              viewBox="0 0 362.459 362.369"
            >
              <g
                id="Group_116"
                data-name="Group 116"
                transform="translate(-6822 -4116)"
                opacity="0.13"
              >
                <path
                  id="Path_270"
                  data-name="Path 270"
                  d="M195.192,788.518a4.283,4.283,0,0,0-4.988,3.765,4.424,4.424,0,0,0,3.788,4.988A92.662,92.662,0,0,0,257.524,628.16a4.425,4.425,0,0,0-4.871,7.389A83.815,83.815,0,0,1,195.216,788.5Z"
                  transform="translate(6796.931 3680.176)"
                  fill="#d9d9d9"
                />
                <path
                  id="Path_271"
                  data-name="Path 271"
                  d="M262.633,621.993A92.662,92.662,0,0,0,199.1,791.1a4.447,4.447,0,0,0,6.141-1.27,4.4,4.4,0,0,0-1.271-6.118A83.814,83.814,0,0,1,261.41,630.77a4.283,4.283,0,0,0,4.988-3.788,4.4,4.4,0,0,0-3.765-4.989Z"
                  transform="translate(6752.808 3671.683)"
                  fill="#d9d9d9"
                />
                <path
                  id="Path_272"
                  data-name="Path 272"
                  d="M262.036,622.7l-88.45,106.051A4.424,4.424,0,0,0,176.974,736h37.813L190.5,800.728a4.424,4.424,0,0,0,7.53,4.4l88.5-106.074a4.424,4.424,0,0,0-3.412-7.247H245.282l24.283-64.732a4.424,4.424,0,0,0-7.53-4.377Zm-27.271,71.979a4.424,4.424,0,0,0,4.141,5.976H273.66l-65.673,78.779,17.365-46.307a4.424,4.424,0,0,0-4.141-5.976H186.457l65.673-78.779Z"
                  transform="translate(6773.124 3671.66)"
                  fill="#d9d9d9"
                />
                <path
                  id="Path_274"
                  data-name="Path 274"
                  d="M135,609.415a22.095,22.095,0,1,0,22.095-22.095A22.095,22.095,0,0,0,135,609.415Zm8.847,0a13.224,13.224,0,1,0,.047,0Z"
                  transform="translate(6722.295 3625.906)"
                  fill="#d9d9d9"
                />
                <path
                  id="Path_275"
                  data-name="Path 275"
                  d="M121.577,591.2A4.518,4.518,0,0,0,120,594.614v70.708a22.118,22.118,0,0,0,22.095,22.095h2.353a39.742,39.742,0,0,0,66.1,0H391.915a39.742,39.742,0,0,0,66.1,0h2.353a22.119,22.119,0,0,0,22.095-22.1v-35.3a4.376,4.376,0,0,0-1.293-3.105l-17.672-17.7a4.566,4.566,0,0,0-1.741-1.059L408.739,590.5l-69.908-43.813a4.351,4.351,0,0,0-2.353-.682H177.437a4.423,4.423,0,0,0-2.824,1.012Zm180.288-36.354,70.591,44.19h-195.3l20.612-44.19Zm92.121,110.592a30.962,30.962,0,1,0,0-.118Zm-247.467,0a30.97,30.97,0,1,0,0-.118ZM128.847,596.8l50.213-41.837h9.177L166.4,601.7a4.4,4.4,0,0,0,4.024,6.282H388.032a4.424,4.424,0,0,0,2.353-8.165l-71.767-44.849h16.73l69.626,43.507,52.966,17.789,15.648,15.648v33.531a13.177,13.177,0,0,1-11.036,12.941,39.343,39.343,0,0,0,2.212-12.941,39.79,39.79,0,0,0-79.555,0,39.2,39.2,0,0,0,2.353,13.248H214.9a39.2,39.2,0,0,0,2.353-13.247,39.79,39.79,0,0,0-79.556,0,39.343,39.343,0,0,0,2.212,12.941,13.177,13.177,0,0,1-11.036-12.942Z"
                  transform="translate(6702 3570)"
                  fill="#d9d9d9"
                />
                <path
                  id="Path_276"
                  data-name="Path 276"
                  d="M199.544,576.05h17.671a4.424,4.424,0,1,1,0,8.847H199.544a4.424,4.424,0,1,1,0-8.847Z"
                  transform="translate(6803.639 3610.658)"
                  fill="#d9d9d9"
                />
                <path
                  id="Path_277"
                  data-name="Path 277"
                  d="M169.544,576.05h17.671a4.424,4.424,0,1,1,0,8.847H169.544a4.424,4.424,0,1,1,0-8.847Z"
                  transform="translate(6763.048 3610.658)"
                  fill="#d9d9d9"
                />
                <path
                  id="Path_279"
                  data-name="Path 279"
                  d="M146.94,723.249a4.424,4.424,0,0,1-4.4-4.424V621.6a4.424,4.424,0,0,1,8.824,0v92.8h13.271a4.424,4.424,0,0,1,0,8.847Z"
                  transform="translate(6732.497 3666.737)"
                  fill="#d9d9d9"
                />
              </g>
            </svg>
          </div>
        </div>
        <div className="serves2">
          <div className="serves-detail">
            <span
              className="myHome-highlight"
              data-aos="fade-in"
              data-aos-duration="1000"
            >
              ‘My Daddy’s Home’
            </span>{" "}
            – A decade old legacy of Homestays Lifestyle @ Lifespaces recognized
            by Google too. Hosting guests from all over the globe, for
            long-stays to short stays as refresh stopovers (as short as an
            hour’s stay) for Individuals, families, corporates, school trips,
            weddings & other programs.
          </div>
          <div className="what-we-do-container drives2">
            <span data-aos="flip-up" data-aos-duration="1000">
              WHAT WE DO
            </span>
            <div className="serves-block drives-block">
              <div
                className="serveBox block"
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <img src={myHome1} alt="car-image" />
                <div>
                  Unique concept of spare home spaces amongst homely atmosphere
                  as additional revenue for owners with employment to locals.
                </div>
              </div>
              <div
                className="serveBox block"
                data-aos="fade-in"
                data-aos-duration="1000"
              >
                <img src={myHome2} alt="car-image" />
                <div>
                  Premier Homestays from 2004, closeby to Pune International
                  Airport as a competitive option to the cozy-costly hotels.
                </div>
              </div>
              <div
                className="serveBox block"
                data-aos="fade-left"
                data-aos-duration="1000"
              >
                <img src={myHome3} alt="car-image" />
                <div>
                  Very soon introducing - - Transit Infrastructure of Peapod
                  Lounges in Waiting, Stays, Offices, Logistics, Training & many
                  more.
                </div>
              </div>
            </div>
            <svg
              className="myHome-svg"
              xmlns="http://www.w3.org/2000/svg"
              width="276.304"
              height="910.732"
              viewBox="0 0 276.304 910.732"
            >
              <g
                id="Group_208"
                data-name="Group 208"
                transform="translate(12378.47 -7870.572)"
                opacity="0.08"
              >
                <path
                  id="Path_423"
                  data-name="Path 423"
                  d="M198.2,315.808l-1.117-28.918H125.252L124.2,315.808Z"
                  transform="translate(-12306.746 8123.734)"
                  fill="#f7f7f7"
                />
                <path
                  id="Path_447"
                  data-name="Path 447"
                  d="M161.076,316.933a19.323,19.323,0,1,1-19.323-19.323A19.323,19.323,0,0,1,161.076,316.933Z"
                  transform="translate(-12316.609 8183.469)"
                  fill="#8cc53f"
                />
                <path
                  id="Path_448"
                  data-name="Path 448"
                  d="M133.105,300.133a19.323,19.323,0,1,1-19.323-19.323A19.323,19.323,0,0,1,133.105,300.133Z"
                  transform="translate(-12472.47 8089.854)"
                  fill="#8cc53f"
                />
                <path
                  id="Path_450"
                  data-name="Path 450"
                  d="M132.746,304.5a13.145,13.145,0,1,1-12.816-12.816A13.145,13.145,0,0,1,132.746,304.5Z"
                  transform="translate(-12405.594 8150.42)"
                  fill="#8cc53f"
                />
                <rect
                  id="Rectangle_156"
                  data-name="Rectangle 156"
                  width="201.706"
                  height="350.701"
                  transform="translate(-12341.202 8430.471)"
                  fill="#414042"
                />
                <path
                  id="Path_491"
                  data-name="Path 491"
                  d="M156.327,309.59h26.289A50.607,50.607,0,0,1,233.224,360.2V531.079H105.72V360.2a50.607,50.607,0,0,1,50.607-50.608Z"
                  transform="translate(-12409.723 8250.225)"
                  fill="#f7f7f7"
                />
                <rect
                  id="Rectangle_157"
                  data-name="Rectangle 157"
                  width="276.302"
                  height="559.9"
                  rx="5.34"
                  transform="translate(-12378.47 7870.572)"
                  fill="#8abb34"
                />
                <path
                  id="Path_492"
                  data-name="Path 492"
                  d="M200.393,317.8a46.007,46.007,0,1,1-46.006-46.007A46.007,46.007,0,0,1,200.393,317.8Z"
                  transform="translate(-12394.903 8039.591)"
                  fill="#f7f7f7"
                />
                <path
                  id="Path_493"
                  data-name="Path 493"
                  d="M165.694,277.122A44.893,44.893,0,0,0,151.3,271.93l-40.88,53.039h35.885L121.264,357.3a43.637,43.637,0,0,0,14.591,5l40.091-51.922H140.127Z"
                  transform="translate(-12383.536 8040.37)"
                  fill="#8abb34"
                />
                <rect
                  id="Rectangle_158"
                  data-name="Rectangle 158"
                  width="33.782"
                  height="33.782"
                  transform="translate(-12257.272 8469.315)"
                  fill="#bd1e2d"
                />
                <rect
                  id="Rectangle_159"
                  data-name="Rectangle 159"
                  width="133.156"
                  height="8.15"
                  transform="translate(-12306.895 8513.744)"
                  fill="#f7f7f7"
                />
                <rect
                  id="Rectangle_160"
                  data-name="Rectangle 160"
                  width="33.782"
                  height="33.782"
                  transform="translate(-12207.59 8469.315)"
                  fill="#40b1e5"
                />
                <rect
                  id="Rectangle_161"
                  data-name="Rectangle 161"
                  width="33.782"
                  height="33.782"
                  transform="translate(-12273.116 8503.163) rotate(180)"
                  fill="#40b1e5"
                />
                <rect
                  id="Rectangle_162"
                  data-name="Rectangle 162"
                  width="224.578"
                  height="277.288"
                  transform="translate(-12352.641 7940.831)"
                  fill="#414042"
                />
                <rect
                  id="Rectangle_163"
                  data-name="Rectangle 163"
                  width="190.336"
                  height="198.945"
                  transform="translate(-12335.553 7958.509)"
                  fill="#5fc8d7"
                />
                <path
                  id="Path_494"
                  data-name="Path 494"
                  d="M146.754,224.571a16.957,16.957,0,1,1-16.891-16.891A16.957,16.957,0,0,1,146.754,224.571Z"
                  transform="translate(-12370.052 7682.348)"
                  fill="#414042"
                />
                <path
                  id="Path_495"
                  data-name="Path 495"
                  d="M138.475,220.782a12.487,12.487,0,1,1-12.422-12.422A12.487,12.487,0,0,1,138.475,220.782Z"
                  transform="translate(-12366.371 7686.136)"
                  fill="#007890"
                />
                <path
                  id="Path_496"
                  data-name="Path 496"
                  d="M130.227,217.048a8.018,8.018,0,1,1-8.018-8.018A8.018,8.018,0,0,1,130.227,217.048Z"
                  transform="translate(-12362.526 7689.869)"
                  fill="#5fc8d7"
                />
                <path
                  id="Path_497"
                  data-name="Path 497"
                  d="M124.266,214.262a4.8,4.8,0,1,1-4.732-4.732A4.732,4.732,0,0,1,124.266,214.262Z"
                  transform="translate(-12359.85 7692.654)"
                  fill="#414042"
                />
                <path
                  id="Path_498"
                  data-name="Path 498"
                  d="M119.792,211.7a2.136,2.136,0,0,1-4.272,0,2.169,2.169,0,0,1,2.169-2.169,2.1,2.1,0,0,1,2.1,2.169Z"
                  transform="translate(-12355.115 7692.654)"
                  fill="#fff"
                />
                <path
                  id="Path_499"
                  data-name="Path 499"
                  d="M322.9,215.41,305.81,233.09h-190.4L98.32,215.41Z"
                  transform="translate(-12450.959 7725.419)"
                  fill="#323233"
                />
                <path
                  id="Path_500"
                  data-name="Path 500"
                  d="M98.32,309.033l17.088-60.663h190.4L322.9,309.033Z"
                  transform="translate(-12450.959 7909.088)"
                  fill="#323233"
                />
                <path
                  id="Path_501"
                  data-name="Path 501"
                  d="M106.64,284.048l8.807-33.848h97.665l8.741,33.848Z"
                  transform="translate(-12404.594 7919.284)"
                  fill="#2da2a5"
                />
                <path
                  id="Path_502"
                  data-name="Path 502"
                  d="M108.14,274.939l5.521-23.989H149.94l5.455,23.989Z"
                  transform="translate(-12396.241 7923.462)"
                  fill="#5fc8d7"
                />
                <circle
                  id="Ellipse_42"
                  data-name="Ellipse 42"
                  cx="31.942"
                  cy="31.942"
                  r="31.942"
                  transform="translate(-12345.013 8243.619)"
                  fill="#7fa32e"
                />
                <path
                  id="Path_503"
                  data-name="Path 503"
                  d="M121.61,293.422a31.942,31.942,0,1,0,9.379-22.61A32.008,32.008,0,0,0,121.61,293.422Z"
                  transform="translate(-12321.179 7982.139)"
                  fill="#7fa32e"
                />
                <path
                  id="Path_504"
                  data-name="Path 504"
                  d="M116.33,254.325c0,1.972,2.3,3.615,5.126,3.615s5.061-1.643,5.061-3.615-2.3-3.615-5.061-3.615S116.33,252.616,116.33,254.325Z"
                  transform="translate(-12350.6 7922.125)"
                  fill="#75e2ea"
                />
                <path
                  id="Path_505"
                  data-name="Path 505"
                  d="M118.69,254.325c0,1.972,2.3,3.615,5.126,3.615s5.061-1.643,5.061-3.615-2.3-3.615-5.061-3.615S118.69,252.616,118.69,254.325Z"
                  transform="translate(-12337.453 7922.125)"
                  fill="#75e2ea"
                />
                <path
                  id="Path_506"
                  data-name="Path 506"
                  d="M121.06,254.325c0,1.972,2.235,3.615,5.061,3.615s5.061-1.643,5.061-3.615-2.3-3.615-5.061-3.615S121.06,252.616,121.06,254.325Z"
                  transform="translate(-12324.244 7922.125)"
                  fill="#75e2ea"
                />
                <path
                  id="Path_507"
                  data-name="Path 507"
                  d="M219.858,281.381a33.978,33.978,0,0,0,2.826-10.253h0a11.788,11.788,0,0,1,0-1.511q0-.986,0-1.972a4.206,4.206,0,0,0-1.972-3.812h0a7.824,7.824,0,0,0-3.812-1.117h0a69.664,69.664,0,0,0,.328-20.44h0v-2.629a1.9,1.9,0,0,1,0-.591v-1.446a4.283,4.283,0,0,0,0-.855v-1.38a2.351,2.351,0,0,1,0-.591,4.009,4.009,0,0,1,0-.789,1.97,1.97,0,0,1,0-.526l-.394-.789a3.7,3.7,0,0,1,0-.526l-.46-.723v-.46l-.526-.789v-.329a7.018,7.018,0,0,0-.92-1.052h0c3.549-1.643,4.929-7.558,1.38-7.3a20.641,20.641,0,0,0-5.915,1.774,17.022,17.022,0,0,1-9.924.591,75.322,75.322,0,0,0-33.322-.854,43.835,43.835,0,0,0-7.032,2.3,66.851,66.851,0,0,0-6.178,3.023h0c-14.2,7.821-27.21,21.1-29.773,27.67a18.271,18.271,0,0,0-17.088,15.248,42.972,42.972,0,0,0,0,8.675h0v6.77a1.908,1.908,0,0,0,0,.592v2.3a1.909,1.909,0,0,1,0,.591v.394h0v.46h0v.657h0a7.493,7.493,0,0,0,4.075,1.512h2.826v-.92h3.418a10.384,10.384,0,0,0,19.717-1.117h3.155a7.616,7.616,0,0,1,0,1.183h0c16.365-.657,37.068-1.314,53.171-1.446a3.282,3.282,0,0,1,0-.789h3.089a10.384,10.384,0,0,0,20.637-1.643,10.646,10.646,0,0,0-.46-2.892,10.379,10.379,0,0,0,2.629-2.037l.591,1.643h0l.986-1.709a13.184,13.184,0,0,0,.657-1.38Z"
                  transform="translate(-12403.39 7759.349)"
                  fill="#2da2a5"
                />
                <rect
                  id="Rectangle_164"
                  data-name="Rectangle 164"
                  width="145.512"
                  height="7.624"
                  transform="translate(-12313.074 8079.443)"
                  fill="#2da2a5"
                />
                <rect
                  id="Rectangle_165"
                  data-name="Rectangle 165"
                  width="44.824"
                  height="7.624"
                  transform="translate(-12313.074 8097.712)"
                  fill="#2da2a5"
                />
                <rect
                  id="Rectangle_166"
                  data-name="Rectangle 166"
                  width="72.756"
                  height="7.624"
                  transform="translate(-12313.074 8115.261)"
                  fill="#2da2a5"
                />
                <rect
                  id="Rectangle_167"
                  data-name="Rectangle 167"
                  width="72.756"
                  height="7.624"
                  transform="translate(-12313.074 8134.848)"
                  fill="#2da2a5"
                />
                <path
                  id="Path_508"
                  data-name="Path 508"
                  d="M370.469,484.331V248.12c-32.862,72.3-107.064,148.273-276.039,157.737v78.408a38.448,38.448,0,0,0,38.449,38.449h199.34A38.449,38.449,0,0,0,370.469,484.331Z"
                  transform="translate(-12472.635 7907.692)"
                  fill="#8abb34"
                  opacity="0.5"
                />
                <path
                  id="Path_509"
                  data-name="Path 509"
                  d="M100.06,313.449c44.955-2.1,156.093-4.4,201.706,19.717V289.92H100.06Z"
                  transform="translate(-12441.261 8140.617)"
                  fill="#414042"
                  opacity="0.5"
                />
              </g>
            </svg>
          </div>
        </div>
      </div>
      <div className="contactUs-container" id="contactUs">
        <div className="contact-main">
          <img src={contactUsImg} alt="contact-img" />
          <div className="contact-opaque"></div>
          <div className="contact-us">Contact Us</div>
        </div>
        <div className="contact-details">
          <div
            className="contact-details-left"
            data-aos="fade-right"
            data-aos-duration="800"
          >
            <span className="left-head">Get in Touch With Us</span>
            <span className="left-text">
              Contact us for any query through any of the following means -
            </span>
            <div className="contact-info">
              <div className="location">
                <svg
                  className="land-location-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 51.557 51.557"
                >
                  <path
                    id="land-location"
                    d="M36.41,4.4A15.037,15.037,0,1,0,15.162,25.686L25.778,36.068l10.631-10.4a15.035,15.035,0,0,0,0-21.267ZM25.778,21.46a6.445,6.445,0,1,1,6.445-6.445A6.444,6.444,0,0,1,25.778,21.46ZM1.648,38.67l13.643.021L13.678,51.557H0l.032-3.59,1.618-9.3Zm14.41-6.1-.228,1.822L2.4,34.373,4.638,21.48H7.551a19.4,19.4,0,0,0,4.608,7.278l3.9,3.815Zm35.5,15.759v3.222H37.879L36.27,38.723l13.63.021,1.656,9.587ZM31.939,38.717,33.55,51.554H18.008L19.622,38.7l2.7,0,3.454,3.377,3.441-3.366,2.72,0Zm17.22-4.268-13.428-.021L35.5,32.571l3.951-3.865a19.264,19.264,0,0,0,4.567-7.227h2.9l2.241,12.966Z"
                    fill="#0d3b5a"
                  />
                </svg>
                <div className="location-name">
                  <span className="contact-head">
                    I Corp Pays Private Limited
                  </span>
                  <span className="contact-head-detail">
                    <span>'My Daddy's Home', </span>
                    Immanuel House, Plot Nos. 146+147, Survey No. 234/A, Lane
                    No. 2/B, Sanjay Park, Airport Road, Pune - 411 032
                    Maharashtra INDIA
                  </span>
                </div>
              </div>
              <div className="location">
                <svg
                  className="contact-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  //   width="38.035"
                  //   height="38.099"
                  viewBox="0 0 38.035 38.099"
                >
                  <path
                    id="phone-call"
                    d="M36.483,17.459A1.587,1.587,0,0,1,34.9,15.872a12.709,12.709,0,0,0-12.7-12.7A1.587,1.587,0,1,1,22.2,0,15.888,15.888,0,0,1,38.07,15.872,1.587,1.587,0,0,1,36.483,17.459Zm-4.761-1.587A9.523,9.523,0,0,0,22.2,6.349a1.587,1.587,0,1,0,0,3.174,6.348,6.348,0,0,1,6.348,6.348,1.587,1.587,0,1,0,3.174,0Zm3.465,19.305,1.444-1.665a4.92,4.92,0,0,0,0-6.947c-.049-.049-3.868-2.987-3.868-2.987a4.92,4.92,0,0,0-6.794.01l-3.025,2.549a20.289,20.289,0,0,1-11-11.021L14.481,12.1a4.92,4.92,0,0,0,.011-6.8S11.551,1.491,11.5,1.442a4.891,4.891,0,0,0-6.866-.073L2.811,2.956C-7.972,15.466,15.247,38.506,28.17,38.091a9.769,9.769,0,0,0,7.017-2.914Z"
                    transform="translate(-0.035 0.002)"
                    fill="#0d3b5a"
                  />
                </svg>

                <div className="location-name">
                  <span className="contact-head">Call Us</span>
                  <span className="contact-head-detail">(+91) 9545795675</span>
                </div>
              </div>
              <div className="location">
                <svg
                  className="contact-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  //   width="31.947"
                  //   height="29.284"
                  viewBox="0 0 31.947 29.284"
                >
                  <g
                    id="envelope_3_"
                    data-name="envelope (3)"
                    transform="translate(0 -1)"
                  >
                    <path
                      id="Path_395"
                      data-name="Path 395"
                      d="M31.886,5.542,20.68,16.747a6.665,6.665,0,0,1-9.414,0L.061,5.542c-.019.21-.061.4-.061.61V22.125a6.664,6.664,0,0,0,6.656,6.656H25.291a6.664,6.664,0,0,0,6.656-6.656V6.152C31.947,5.943,31.9,5.752,31.886,5.542Z"
                      transform="translate(0 1.504)"
                      fill="#0d3b5a"
                    />
                    <path
                      id="Path_396"
                      data-name="Path 396"
                      d="M18.552,16.369,30.71,4.209A6.637,6.637,0,0,0,25.045,1H6.409A6.637,6.637,0,0,0,.744,4.209L12.9,16.369a4,4,0,0,0,5.649,0Z"
                      transform="translate(0.246)"
                      fill="#0d3b5a"
                    />
                  </g>
                </svg>

                <div className="location-name">
                  <span className="contact-head">Email Us</span>
                  <span className="contact-head-detail">
                    admin@icorp.global
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className="contact-details-right"
            data-aos="fade-left"
            data-aos-duration="800"
          >
            <Form onSubmit={handleSubmitContactUs} className="contact-form">
              <Form.Group className="form-group">
                <Form.Control
                  type="text"
                  className="input-field"
                  placeholder="Your Name"
                  name="fName"
                  value={detail.fName}
                  onChange={handleContactUs}
                  required
                />
                <Form.Control
                  type="email"
                  required
                  className="input-field"
                  placeholder="Your Email"
                  name="email"
                  value={detail.email}
                  onChange={handleContactUs}
                />
              </Form.Group>
              <Form.Control
                type="text"
                required
                className="input-field"
                placeholder="Your Phone Number"
                name="phone"
                value={detail.phone}
                onChange={handleContactUs}
              />
              <textarea
                className="input-field"
                placeholder="Your Message"
                name="message"
                value={detail.message}
                onChange={handleContactUs}
              />
              <button>Submit</button>
            </Form>
          </div>
        </div>
        <div className="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.065706257843!2d73.89748637142576!3d18.57107622904802!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c130348a87ed%3A0x4d54165e37cae1b4!2smy%20daddy&#39;s%20home!5e0!3m2!1sen!2sin!4v1707471785631!5m2!1sen!2sin"
            width="100%"
            height="400"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      <div className="footer">
        <span>BinoMon Integrated: Payment Solutions</span>
        <span>Copyright © 2024. All rights reserved.</span>
      </div>
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleClose}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <svg
            className="modal-svg-left"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 426.719 426.691"
          >
            <g
              id="_1"
              data-name="1"
              transform="translate(0 -0.04)"
              opacity="0.06"
            >
              <path
                id="Path_181"
                data-name="Path 181"
                d="M50.463,56.725H38.074L55.119,22.609a5.183,5.183,0,0,0-2.328-6.984,5.238,5.238,0,0,0-6.984,2.328L24.993,59.525a5.183,5.183,0,0,0,4.656,7.511H42.065l-17.072,34.2a5.21,5.21,0,0,0,9.312,4.656L55.119,64.319a5.21,5.21,0,0,0-4.656-7.538Z"
                transform="translate(17.188 5.672)"
                fill="#0773d9"
              />
              <path
                id="Path_182"
                data-name="Path 182"
                d="M9.42,33.634v83.143a26.079,26.079,0,0,0,20.814,25.5v31.788H14.63a5.21,5.21,0,0,0-5.21,5.183V429.09a5.21,5.21,0,0,0,5.21,5.21h104.1a5.238,5.238,0,0,0,5.21-5.21V357.033a43.955,43.955,0,0,0,26.024,9.506,43.762,43.762,0,0,0,26.024-9.506V376.96a15.6,15.6,0,0,0,15.6,15.6h5.127v26.024a15.631,15.631,0,0,0,15.6,15.631h20.841a15.631,15.631,0,0,0,15.6-15.631V392.563H363.277v26.024a15.631,15.631,0,0,0,15.6,15.631h20.813a15.659,15.659,0,0,0,15.631-15.631V392.563h5.21a15.631,15.631,0,0,0,15.6-15.6V325a26.024,26.024,0,0,0-21.867-25.664l-15.908-63.937a25.968,25.968,0,0,0-25.248-19.7H239.089a26,26,0,0,0-25.276,19.7L197.85,299.332A26.024,26.024,0,0,0,175.984,325v18.569c-17.71,16.795-34.366,16.795-52.048,0V179.245a5.21,5.21,0,0,0-5.21-5.183H103.095V142.3a26.052,26.052,0,0,0,20.841-25.525V33.634A26.052,26.052,0,0,0,97.912,7.61H35.444A26.052,26.052,0,0,0,9.42,33.634Zm394.1,265.448H208.659l15.271-60.972a15.6,15.6,0,0,1,15.16-11.806H373.116a15.6,15.6,0,0,1,15.16,11.806ZM425.69,356.34H373.753V335.527H425.8Zm-52.048,36.417h31.234v26.024a5.21,5.21,0,0,1-5.21,5.21H378.853a5.21,5.21,0,0,1-5.21-5.21Zm-166.536,0h31.317v26.024a5.21,5.21,0,0,1-5.183,5.21H212.4a5.21,5.21,0,0,1-5.183-5.21Zm-20.841-57.23h52.158V356.34H186.376Zm0-10.421A15.659,15.659,0,0,1,201.9,309.475H410.115a15.631,15.631,0,0,1,15.6,15.631H373.753a10.393,10.393,0,0,0-10.393,10.421V356.34a10.393,10.393,0,0,0,10.393,10.393H425.8v10.421a5.21,5.21,0,0,1-5.183,5.21H191.587a5.238,5.238,0,0,1-5.21-5.21V366.733h52.048a10.393,10.393,0,0,0,10.421-10.393V335.527a10.421,10.421,0,0,0-10.421-10.421ZM113.571,184.456V423.88H19.841V184.456ZM40.654,174.063V142.829H92.7v31.234ZM19.841,33.634A15.659,15.659,0,0,1,35.444,18H97.912a15.659,15.659,0,0,1,15.659,15.631v83.143a15.631,15.631,0,0,1-15.6,15.6H35.444a15.631,15.631,0,0,1-15.6-15.6Z"
                transform="translate(-9.42 -7.57)"
                fill="#414141"
              />
              <path
                id="Path_183"
                data-name="Path 183"
                d="M104.528,135.289h83.282a5.21,5.21,0,1,1,0,10.393H104.528a5.21,5.21,0,0,1,0-10.393Z"
                transform="translate(150.5 218.581)"
                fill="#414141"
              />
              <path
                id="Path_184"
                data-name="Path 184"
                d="M104.77,127.76h83.282a5.21,5.21,0,1,1,0,10.421H104.77a5.21,5.21,0,1,1,0-10.421Z"
                transform="translate(150.258 205.268)"
                fill="#414141"
              />
              <path
                id="Path_185"
                data-name="Path 185"
                d="M104.77,120.25h83.282a5.21,5.21,0,1,1,0,10.421H104.77a5.21,5.21,0,1,1,0-10.421Z"
                transform="translate(150.258 191.965)"
                fill="#414141"
              />
              <path
                id="Path_186"
                data-name="Path 186"
                d="M89.792,121.982V80.41a5.21,5.21,0,0,0-5.183-5.21H22.141a5.21,5.21,0,0,0-5.21,5.21v41.572a5.238,5.238,0,0,0,5.21,5.321H84.609a5.21,5.21,0,0,0,5.183-5.321ZM79.4,116.772H27.351V85.537H79.4Z"
                transform="translate(3.883 112.162)"
                fill="#0773d9"
              />
              <path
                id="Path_187"
                data-name="Path 187"
                d="M61.957,102.294a5.266,5.266,0,0,1,7.15,1.829,25.691,25.691,0,0,1,3.631,13.053,26.024,26.024,0,0,1-52.048,0,25.414,25.414,0,0,1,3.631-13.053,5.21,5.21,0,1,1,8.952,5.321,14.91,14.91,0,0,0-2.189,7.732,15.631,15.631,0,1,0,31.234,0,14.883,14.883,0,0,0-2.162-7.732,5.238,5.238,0,0,1,1.8-7.15Z"
                transform="translate(10.544 158.594)"
                fill="#414141"
              />
              <path
                id="Path_188"
                data-name="Path 188"
                d="M28.2,123.734V102.92a5.21,5.21,0,0,1,10.421,0v20.814a5.21,5.21,0,1,1-10.421,0Z"
                transform="translate(23.848 152.037)"
                fill="#414141"
              />
              <path
                id="Path_189"
                data-name="Path 189"
                d="M84.851,153.192H22.383a5.21,5.21,0,1,1,0-10.393H84.851a5.21,5.21,0,0,1,0,10.393Z"
                transform="translate(3.641 231.884)"
                fill="#414141"
              />
              <path
                id="Path_190"
                data-name="Path 190"
                d="M84.851,145.652H22.383a5.21,5.21,0,1,1,0-10.393H84.851a5.21,5.21,0,0,1,0,10.393Z"
                transform="translate(3.641 218.528)"
                fill="#414141"
              />
            </g>
          </svg>
          <svg
            className="modal-right-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 154 154"
          >
            <g
              id="Group_97"
              data-name="Group 97"
              transform="translate(-7288 -3724)"
              opacity="0.13"
            >
              <path
                id="Path_283"
                data-name="Path 283"
                d="M633,240.39h27.4L646.19,283a1.88,1.88,0,0,0,3.29,1.72l45.07-60.1a1.88,1.88,0,0,0-1.5-3H665.6L679.81,179a1.89,1.89,0,0,0-3.29-1.73l-45.07,60.11a1.88,1.88,0,0,0,1.55,3.01Zm39.63-51.59-11.36,34.09a1.88,1.88,0,0,0,1.78,2.48h26.29L653.42,273.2l11.36-34.09a1.87,1.87,0,0,0-1.78-2.47H636.71Z"
                transform="translate(6702 3570)"
                fill="#0773d9"
              />
              <path
                id="Path_284"
                data-name="Path 284"
                d="M588.47,307.9l34.21-11.36A75.94,75.94,0,0,0,663,308a77,77,0,1,0-77-77,75.94,75.94,0,0,0,11.46,40.32L586.1,305.53a1.87,1.87,0,0,0,2.37,2.37Zm12.83-36.24a1.87,1.87,0,0,0-.2-1.61A72.19,72.19,0,0,1,589.76,231,73.24,73.24,0,1,1,663,304.24a72.17,72.17,0,0,1-39-11.34,1.85,1.85,0,0,0-1.61-.2l-31.5,10.46Z"
                transform="translate(6702 3570)"
                fill="#414141"
              />
            </g>
          </svg>
          <p>Thank You for contacting us!</p>
          <span>We will get in touch with you soon.</span>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Homescreen;
