import http from "../http-common";

const sendEmail = (data) => {
  return http.post(`/Mail/send`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const EmailService = {
  sendEmail,
};

export default EmailService;
